<div class="search-container">
  <dx-grid mq1="11-1" mq2="9-3">
    <dx-text-input id="search-input" class="search-input" size="m" (autocomplete)="provideAutocomplete($event)"
                   (enterPress)="submit(input.value)" (selectAutocomplete)="submit($event.detail)" [value]="term"
                   [placeholder]="placeholder" #input gaEvent="click" gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[121]}}>id-0000"
                   gaLabel="{{texts[161]}}:{{texts[155]}}>{{texts[121]}}"></dx-text-input>

    <dx-button id="search-button" class="search-icon" [theme]="searchPage? 'default' : 'on-gold'"
               type="primary-m" icon="lupe" (click)="submit(input.value)" label="{{showIconOnly ? '' : texts[164]}}"></dx-button>
  </dx-grid>
</div>
