import {fromEvent as observableFromEvent, Observable} from "rxjs"
import {map} from "rxjs/operators"
import {Injectable} from "@angular/core"

@Injectable({
  providedIn: 'root'
})
export class BrowsersizeService {
  windowWidth = 0
  isLowerThan: {
    [key:string]: boolean
    mq1: boolean
    mq2: boolean
    mq3: boolean
    mq4: boolean
    mq5: boolean
  } = {
    mq1: false,
    mq2: false,
    mq3: false,
    mq4: false,
    mq5: false
  }

  breakpoints: {
    [key: string]: number
  } = {
    mq1: 479,
    mq2: 759,
    mq3: 1023,
    mq4: 1279,
    mq5: 1460
  }

  constructor() {
    this.setWidth(window.innerWidth)
    Object.keys(this.isLowerThan).forEach((mq) => {
      this.isLowerThan[mq] = this.isLowerThanMq(mq)
      this.listenOnLowerThanMqSizeChange(mq).subscribe((bool) => {
        this.isLowerThan[mq] = bool
        this.setWidth(window.innerWidth)
      })
    })
  }

  public setWidth(width: number) {
    this.windowWidth = width
  }

  public isLowerThanMq(mq: string): boolean {
    return this.isLowerThanMqWidth(mq, this.windowWidth)
  }

  public listenOnLowerThanMqSizeChange(mq: string): Observable<boolean> {
    return observableFromEvent(window, 'resize').pipe(
      // @ts-ignore
      map((event: Event) => this.isLowerThanMqWidth(mq, event.target['innerWidth'])))
  }

  private isLowerThanMqWidth(mq: string, width: number): boolean {
    return width <= this.breakpoints[mq]
  }
}
