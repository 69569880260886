import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {AnwendungssucheService} from "../../services/anwendungssuche.service"
import {Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import { HttpClient } from "@angular/common/http"
import {Components} from "@dvag/design-system"
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import DxTextInput = Components.DxTextInput
import {TextService} from "../../services/text.service"
import {DesignSystemModule} from '@dvag/design-system-angular'
import { InsightsService } from '../../services/insights.service'

@Component({
  selector: 'app-searchinput',
  standalone: true,
  templateUrl: './searchinput.component.html',
  styleUrls: ['./searchinput.component.scss'],
  imports: [
    DesignSystemModule,
    NgxGoogleAnalyticsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SearchinputComponent implements OnInit {

  @Input() searchPage: boolean = false
  @Input() term: string = ''
  @Input() placeholder: string = ''
  @Input() showIconOnly = false
  @Output() termChange: EventEmitter<string> = new EventEmitter<string>()
  @ViewChild('input') input!: DxTextInput
  private searchHistory: Array<string> = []
  searchTermChanged: Subject<string> = new Subject<string>()
  static maxAutocompleteResults = 20
  static debounceTimeMs = 1000
  texts: Array<string> = []

  ngOnInit() {
    this.texts = this.textService.getTextsForIds([], 121,155,161,164)
  }

  constructor(private anwendungssucheService: AnwendungssucheService, private http: HttpClient, private insightsService: InsightsService,
              private gaService: GoogleAnalyticsService, public textService: TextService) {
    const history = localStorage.getItem('dvag-search-history')
    if (history) {
      this.searchHistory = JSON.parse(history)
    }
  }

  submit(term: string) {
    // no action if search term missing
    if (!term) {
      return
    }
    this.gaService.event(this.texts[121], this.texts[161] + ":" + this.texts[155] + ">" + this.texts[121] + ">" + "id-0000", term)
    this.insightsService.logEvent(this.texts[121] + ': ' + term)
    if (term.trim() && !this.searchHistory.includes(term)) {
      this.searchHistory.push(term)
    }
    this.termChange.emit(term)
    localStorage.setItem('dvag-search-history', JSON.stringify(this.searchHistory))
  }

  provideAutocomplete(e: CustomEvent) {
    const term = e.detail.value

    const searchHistory = this.searchHistory
      .map((item) => {
        return {label: item, value: item, data: item}
      })

    this.searchTermChanged.pipe(debounceTime(SearchinputComponent.debounceTimeMs), distinctUntilChanged((previous: any, current: any) => previous.value === current.value))
      .subscribe((term) => {
        e.detail.provideValues(
          searchHistory
            .filter((historyItem) => historyItem.value.indexOf(term) > -1)
            .reverse()
            .slice(0, SearchinputComponent.maxAutocompleteResults -1)
        )
      })
    this.searchTermChanged.next(term)
  }
}
